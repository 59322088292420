import React, { FC, useContext } from "react";
import { graphql } from "gatsby";
import { PageQuery, BlockFragment } from "../../graphql-types";
import { Blocks } from "../components/blocks";
import { colors, spaceRem, targetBaseFontSizePx } from "../global-style";
import { LayoutContext } from "../components/layout";
import { css } from "styled-components";
import BackgroundImage, { IFluidObject } from "gatsby-background-image";
import { useLocation } from "@reach/router";

export const query = graphql`
  query NotFoundPage {
    header: file(relativePath: { eq: "page-template-header.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const NotFoundPage: FC<{ data: PageQuery }> = props => {
  const { remInPx } = useContext(LayoutContext);

  const location = useLocation();
  const currentLanguage = (location.pathname.split("/").filter(v => v)[0]) as "nl" | "en" || "en";

  const cookieText = {
    "en": {
      buttonText: "Accept",
      declineButtonText: "Decline",
      message: "This website uses cookies to improve your experience and optimize our services."
    },
    "nl": {
      buttonText: "Accepteren",
      declineButtonText: "Afwijzen",
      message: "Deze website maakt gebruik van cookies om uw ervaring te verbeteren en onze diensten te optimaliseren."
    },
  };

  const currentCookieText = cookieText[currentLanguage];

  return (
    <>
      <BackgroundImage
        style={{
          position: "relative",
          height: "30vw",
          minHeight: "40vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        fluid={props.data.header?.childImageSharp?.fluid as IFluidObject}
      >
        <h1
          style={{
            hyphens: "none",
            maxWidth: "30ch",
            textAlign: "center",
            color: colors.white,
            paddingLeft: `${0.5 * spaceRem}rem`,
            paddingRight: `${0.5 * spaceRem}rem`,
          }}
          css={(() => {
            const minFontSizeRem = 1.25;
            const maxFontSizeRem = 3;
            const vwRangeStartPx = 500;

            return css`
              font-size: ${(minFontSizeRem *
                (remInPx || targetBaseFontSizePx)) /
              (vwRangeStartPx / 100)}vw;

              @media (max-width: ${vwRangeStartPx}px) {
                font-size: ${minFontSizeRem}rem;
              }

              @media (min-width: ${vwRangeStartPx *
              (maxFontSizeRem / minFontSizeRem)}px) {
                font-size: ${maxFontSizeRem}rem;
              }
            `;
          })()}
        >
          404 -{" "}
          {currentLanguage === "nl"
            ? "Deze pagina bestaat niet"
            : "This page no longer exists"}
        </h1>
      </BackgroundImage>
    </>
  );
};

export default NotFoundPage;
